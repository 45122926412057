import * as React from "react";
import styles from "./RecommendedServiceSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";
import InitialServices from "./InitialServices";
import LifecycleServices from "./LifecycleServices";
import { PageFourDTO, PageThreeDTO, PageTwoDTO } from "../../api";

const RecommendedServiceSection: React.FC<{initialServices: PageThreeDTO, lifecycleServices: PageFourDTO, config: PageTwoDTO}> = (
  {initialServices, lifecycleServices, config}
) => {
  const { t } = useTranslation("common");
  return (
    <div>
      <div className={styles.blackHeaderWrapper}>
        <BlackHeaderBar text={t("step4.recommendedService.headerText")} />
        <CRow>
          <CCol>
            <h4 className={styles.sectionTitle}>
              <strong>
                {t(`step4.recommendedService.firstSection.headerText`)}
              </strong>
            </h4>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            { config.equipmentQualificationNeeded == "Yes" ?
              <InitialServices
                isTestPippette={config.isForTestPipettes !== "No" ? true : false}
                equipmentInstallation={true}
                calibrationCertificate={true}
                minWeightCertificate={true}
                calibration={true}
                conformityCertificate={true}
                phEur={config.requiresComplianceEU == "Yes" ? true : false}
                usp={config.requiresComplianceUSA == "Yes" ? true : false}
                model={initialServices.equipmentPackageDTO.packageNumber}
                iqOqDocumentation={initialServices.equipmentPackageDTO.documentationIQOQ}

                balanceConformityCertificate={initialServices.optionalServicesDTO.balanceConformityCertificate}
                balanceTestProtocol={initialServices.optionalServicesDTO.balanceTestProtocol}
                repeatabilityMeasurements={initialServices.optionalServicesDTO.repeatabilityMeasurements}
              />
            :
              <InitialServices
                isTestPippette={config.isForTestPipettes !== "No" ? true : false}
                equipmentInstallation={true}
                calibrationCertificate={true}
                minWeightCertificate={true}
                calibration={true}
                conformityCertificate={true}
                phEur={config.requiresComplianceEU == "Yes" ? true : false}
                usp={config.requiresComplianceUSA == "Yes" ? true : false}
                model={initialServices.installationPackageDTO.packageNumber}

                balanceConformityCertificate={initialServices.optionalServicesDTO.balanceConformityCertificate}
                balanceTestProtocol={initialServices.optionalServicesDTO.balanceTestProtocol}
                repeatabilityMeasurements={initialServices.optionalServicesDTO.repeatabilityMeasurements}
              />
            }
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <h4 className={styles.secondSectionTitle}>
              <strong>
                {t(`step4.recommendedService.secondSection.headerText`)}
              </strong>
            </h4>
          </CCol>
        </CRow>
        <CRow className={styles.subHeader}>
          <CCol xs="6" lg="6">
            <span>
              {t(`step4.recommendedService.secondSection.serviceFrequency`)}
            </span>
          </CCol>
          <CCol xs="6" lg="6" className={styles.rightCol}>
            <span>
                {lifecycleServices.serviceFrequency}
            </span>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            { config.fullMaintenanceReqPos == "Yes" ?
              <LifecycleServices

                isTestPippette={config.isForTestPipettes !== "No" ? true : false}
                equipmentMaintenance={true}
                calibrationCertificate={true}
                minWeightCertificate={true}
                calibration={true}
                conformityCertificate={true}
                phEur={config.requiresComplianceEU == "Yes" ? true : false}
                usp={config.requiresComplianceUSA == "Yes" ? true : false}
                isBasicPackage={false}
                model={lifecycleServices.standardMaintanancePackageDTO.packageNumber}
                
                balanceConformityCertificate={lifecycleServices.optionalServicesDTO.balanceConformityCertificate}
                balanceTestProtocol={lifecycleServices.optionalServicesDTO.balanceTestProtocol}
                repeatabilitMeasurements={lifecycleServices.optionalServicesDTO.repeatabilityMeasurements}
                checkOfConformity={lifecycleServices.optionalServicesDTO.checkOfConformity}
              />
            :
              <LifecycleServices
                isTestPippette={config.isForTestPipettes !== "No" ? true : false}
                equipmentMaintenance={true}
                calibrationCertificate={true}
                minWeightCertificate={true}
                calibration={true}
                conformityCertificate={true}
                phEur={config.requiresComplianceEU == "Yes" ? true : false}
                usp={config.requiresComplianceUSA == "Yes" ? true : false}
                isBasicPackage={true}
                model={lifecycleServices.basicMaintanancePackageDTO.packageNumber}
                
                balanceConformityCertificate={lifecycleServices.optionalServicesDTO.balanceConformityCertificate}
                balanceTestProtocol={lifecycleServices.optionalServicesDTO.balanceTestProtocol}
                repeatabilitMeasurements={lifecycleServices.optionalServicesDTO.repeatabilityMeasurements}
                checkOfConformity={lifecycleServices.optionalServicesDTO.checkOfConformity}
              />
            }
          </CCol>
        </CRow>
      </div>
    </div>
  );
};

export default RecommendedServiceSection;
