import * as React from "react";
import styles from "./InitialServices.module.scss";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";
import Check from "../../assets/icons/check.svg";
import i18next from "i18next";
import CollapsibleRow from "../CollapsibleRow/CollapsibleRow";

interface InitialServicesProps {
  iqOqDocumentation?: boolean;
  equipmentInstallation: boolean;
  calibrationCertificate: boolean;
  minWeightCertificate: boolean;
  conformityCertificate: boolean;
  calibration: boolean;
  usp: boolean;
  phEur: boolean;
  model: string;
  isTestPippette: boolean;
  balanceConformityCertificate: string;
  balanceTestProtocol: string;
  repeatabilityMeasurements: string;
}

const InitialServices: React.FC<InitialServicesProps> = (props) => {
  const { t } = useTranslation("common");

  const createRow = (
    text: string,
    value: boolean,
    isNested: boolean,
    isLastRow: boolean
  ) => {
    return (
      <CRow>
        <CCol className={styles.textCol} xs="10" lg="8">
          <p className={`${styles.text} ${isNested ? styles.nested : ""}`}>
            {text}
          </p>
        </CCol>
        <CCol
          className={`${styles.valueCol} ${isLastRow ? styles.lastRow : ""}`}
          xs="2"
          lg="4"
        >
          <div className={styles.value}>
            {value ? <img src={Check} /> : <p className={styles.dash}>{"-"}</p>}
          </div>
        </CCol>
      </CRow>
    );
  };

  const rows = [
    "equipmentInstallation",
    "iqOqDocumentation",
    "calibrationCertificate",
    "minWeightCertificate",
    "conformityCertificate",
    "calibration",
    "usp",
    "phEur",
  ];

  const isPharmaInstallationPackage = props.iqOqDocumentation === undefined;
  const rowsLastIndex = isPharmaInstallationPackage
    ? rows.length - 2
    : rows.length - 1;

  return (
    <>
      <CollapsibleRow
        mainText={t(
          `step4.recommendedService.firstSection.${
            isPharmaInstallationPackage
              ? "pharmaInstallationPackage"
              : "equipmentQualificationPackage"
          }`
        )}
        rightText={props.model}
        showInitially={true}
        children={
          <div className={styles.section}>
            {rows
              .filter((x) => props[x] !== undefined)
              .map((x, index) =>
                createRow(
                  t(`step4.recommendedService.${x}.text`),
                  props[x],
                  i18next.exists(
                    `common:step4.recommendedService.${x}.isNested`
                  ),
                  index === rowsLastIndex
                )
              )}
          </div>
        }
      />
      <>
        <CRow className="ml-1">
          <CCol className={styles.whiteTextCol} lg="12">
            <p className={`${styles.whiteText}`}>
              {t(`step4.recommendedService.optionalServices.text`)}
            </p>
          </CCol>
        </CRow>
        {props.balanceConformityCertificate &&
          <CRow className={`${styles.whiteRow} pl-2`}>
            <CCol 
              className={`${styles.whiteTextCol} ${styles.nestedWhite}`} 
              xs="12"
              lg="8"
            >
              <p className={`${styles.whiteText}`}>
                {t(`step4.recommendedService.balanceConformityCertificate.text`)}
              </p>
            </CCol>
            <CCol className={`${styles.whiteValueCol}`} xs="12" lg="4">
              {
                <div className={styles.textValue}>
                  <p className={styles.dash}>
                    <strong>{props.balanceConformityCertificate}</strong>
                  </p>
                </div>
              }
            </CCol>
          </CRow>
        }
      <CRow className={`${styles.whiteRow} pl-2`}>
          <CCol
            className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
            xs="12"
            lg="8"
          >
            <p className={`${styles.whiteText}`}>
              {t(`step4.recommendedService.balanceTestProtocol.text`)}
            </p>
          </CCol>
          <CCol className={`${styles.whiteValueCol}`} xs="12" lg="4">
            {
              <div className={styles.textValue}>
                <p className={styles.dash}>
                  <strong>{props.balanceTestProtocol}</strong>
                </p>
              </div>
            }
          </CCol>
        </CRow>
        <CRow className={`${styles.whiteRow} pl-2`}>
          <CCol
            className={`${styles.whiteTextCol} ${styles.nestedWhite}`}
            xs="12"
            lg="8"
          >
            <p className={`${styles.whiteText}`}>
              {t(`step4.recommendedService.repeatabilitMeasurements.text`)}
            </p>
          </CCol>
          <CCol className={`${styles.whiteValueCol}`} lg="4">
            {
              <div className={styles.textValue}>
                <p className={styles.dash}>
                  <strong>{props.repeatabilityMeasurements}</strong>
                </p>
              </div>
            }
          </CCol>
        </CRow>
        <hr/>
      </>
    </>

  );
};

export default InitialServices;
