/* tslint:disable */
/* eslint-disable */
/**
 * Calculate assessment API.
 * API for requesting Assessment via Service Finder Tool
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Information about the risk assessment score and criteria
 * @export
 * @interface RiskAssessmentData
 */
export interface RiskAssessmentData {
    /**
     * Impact of incorrect measurements on patient safety, human | animal health or the environment
     * @type {string}
     * @memberof RiskAssessmentData
     */
    impactHealthScore: string;
    /**
     * Impact of incorrect measurement on production or business processes 
     * @type {string}
     * @memberof RiskAssessmentData
     */
    impactBusinessScore: string;
    /**
     * Impact Score (SI)
     * @type {number}
     * @memberof RiskAssessmentData
     */
    impactScore: number;
    /**
     * Occurrence: Probability of incorrect measurement (caused e.g. by the installation place, environmental effects, weighing good or the user)
     * @type {string}
     * @memberof RiskAssessmentData
     */
    probabilityOccurrenceScore: string;
    /**
     * Detection: Probability  that incorrect measurement results stay undetected
     * @type {string}
     * @memberof RiskAssessmentData
     */
    probabilityDetectionScore: string;
    /**
     * Probability Score (SP)
     * @type {number}
     * @memberof RiskAssessmentData
     */
    probabilityScore: number;
    /**
     * Total Risk Score of the weighing process (SR)r
     * @type {number}
     * @memberof RiskAssessmentData
     */
    riskAssessmentScore: number;
}

/**
 * Check if a given object implements the RiskAssessmentData interface.
 */
export function instanceOfRiskAssessmentData(value: object): value is RiskAssessmentData {
    if (!('impactHealthScore' in value) || value['impactHealthScore'] === undefined) return false;
    if (!('impactBusinessScore' in value) || value['impactBusinessScore'] === undefined) return false;
    if (!('impactScore' in value) || value['impactScore'] === undefined) return false;
    if (!('probabilityOccurrenceScore' in value) || value['probabilityOccurrenceScore'] === undefined) return false;
    if (!('probabilityDetectionScore' in value) || value['probabilityDetectionScore'] === undefined) return false;
    if (!('probabilityScore' in value) || value['probabilityScore'] === undefined) return false;
    if (!('riskAssessmentScore' in value) || value['riskAssessmentScore'] === undefined) return false;
    return true;
}

export function RiskAssessmentDataFromJSON(json: any): RiskAssessmentData {
    return RiskAssessmentDataFromJSONTyped(json, false);
}

export function RiskAssessmentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskAssessmentData {
    if (json == null) {
        return json;
    }
    return {
        
        'impactHealthScore': json['impactHealthScore'],
        'impactBusinessScore': json['impactBusinessScore'],
        'impactScore': json['impactScore'],
        'probabilityOccurrenceScore': json['probabilityOccurrenceScore'],
        'probabilityDetectionScore': json['probabilityDetectionScore'],
        'probabilityScore': json['probabilityScore'],
        'riskAssessmentScore': json['riskAssessmentScore'],
    };
}

export function RiskAssessmentDataToJSON(value?: RiskAssessmentData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'impactHealthScore': value['impactHealthScore'],
        'impactBusinessScore': value['impactBusinessScore'],
        'impactScore': value['impactScore'],
        'probabilityOccurrenceScore': value['probabilityOccurrenceScore'],
        'probabilityDetectionScore': value['probabilityDetectionScore'],
        'probabilityScore': value['probabilityScore'],
        'riskAssessmentScore': value['riskAssessmentScore'],
    };
}

