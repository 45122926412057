import * as React from "react";
import styles from "./WeighingProcessSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";

interface WeighingProcessSectionProps {
  weight: string;
  isRequiringEquipmentQualification: string;
  isRequiringUSPharmaCompliance: string;
  isRequiringEUPharmaCompliance: string;
  isBalanceUsedForTest: string;
  isRequiringFullMaintenance: string;
  requiredProcessAccuracy: string;
  safetyFactor: string;
}

const WeighingProcessSection: React.FC<WeighingProcessSectionProps> = (
  props
) => {
  const { t } = useTranslation("common");

  return (
    <div>
      <div className={styles.blackHeaderWrapper}>
        <BlackHeaderBar
          text={t("step4.weighingProcess.headerText")}
          urlToNavigate="/step2"
          hasButton
        />
        <CRow className={styles.firstRow}>
          {[
            "weight",
            "isRequiringEquipmentQualification",
            "isRequiringUSPharmaCompliance",
          ].map((x) => (
            <CCol xs="12" lg="4">
              <div className={styles.header}>
                {t(`step4.weighingProcess.firstSection.${x}`)}
              </div>
              <div className={styles.value}>{props[x]}</div>
            </CCol>
          ))}
        </CRow>
        <CRow className={styles.secondRow}>
          {[
            "isRequiringEUPharmaCompliance",
            "isBalanceUsedForTest",
            "isRequiringFullMaintenance",
          ].map((x) => (
            <CCol xs="12" lg="4">
              <div className={styles.header}>
                {t(`step4.weighingProcess.firstSection.${x}`)}
              </div>
              <div className={styles.value}>{props[x]}</div>
            </CCol>
          ))}
        </CRow>
        <hr />
        <div className={styles.secondRow}>
          <CRow>
            <CCol>
              <h4 className={styles.sectionTitle}>
                <strong>
                  {t(`step4.weighingProcess.secondSection.headerText`)}
                </strong>
              </h4>
            </CCol>
          </CRow>
          <CRow>
            {["requiredProcessAccuracy", "safetyFactor"].map((x) => (
              <>
                <CCol lg="8">
                  <div className={styles.header}>
                    {t(`step4.weighingProcess.secondSection.${x}`)}
                  </div>
                </CCol>
                <CCol lg="4">
                  <div
                    className={`${styles.value}`}
                  >
                    {props[x]}
                  </div>
                </CCol>
              </>
            ))}
          </CRow>
        </div>
      </div>
    </div>
  );
};

export default WeighingProcessSection;
