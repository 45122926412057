import * as React from "react";
import styles from "./RiskAssessmentSection.module.scss";
import BlackHeaderBar from "./BlackHeaderBar";
import { useTranslation } from "react-i18next";
import { CCol, CRow } from "@coreui/react";

interface RiskAssessmentSectionProps {
  subsections: Subsection[];
}

interface Subsection {
  name: string;
  values: ValueAndBackgroundColor[];
}

interface ValueAndBackgroundColor {
  value: string;
}

interface HeaderAndValue extends ValueAndBackgroundColor {
  header: string;
}

const RiskAssessmentSection: React.FC<RiskAssessmentSectionProps> = ({
  subsections,
}) => {
  const { t } = useTranslation("common");

  const createSubSection = (
    headersAndValues: HeaderAndValue[],
    withHorizontalLine?: boolean
  ) => {
    return (
      <>
        <div className={styles.desktopSection}>
          <CRow>
            <CCol lg="8">
              {headersAndValues.map((x) => (
                <div className={styles.header}>{x.header}</div>
              ))}
            </CCol>
            <CCol lg="4">
              {headersAndValues.map((x) => (
                <div
                  className={`${styles.value}`}
                >
                  {x.value}
                </div>
              ))}
            </CCol>
          </CRow>
          {withHorizontalLine && <hr />}
        </div>

        <div className={styles.mobileSection}>
          <CRow>
            <CCol xs="12">
              {headersAndValues.map((x) => (
                <>
                  <div className={styles.header}>{x.header}</div>
                  <div
                    className={`${styles.value}`}
                  >
                    {x.value}
                  </div>
                </>
              ))}
            </CCol>
          </CRow>
          {withHorizontalLine && <hr />}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={styles.blackHeaderWrapper}>
        <BlackHeaderBar
          text={t("step4.riskAssessment.headerText")}
          urlToNavigate="/step1"
          hasButton
        />
      </div>
      <div className={styles.lastRow}>
        {subsections.map((subsection, subsectionIndex) => (
          <>
            <CRow>
              <CCol>
                <h4 className={styles.sectionTitle}>
                  <strong>
                    {t(`step4.riskAssessment.${subsection.name}.headerText`)}
                  </strong>
                </h4>
              </CCol>
            </CRow>
            {createSubSection(
              subsection.values.map((x, valueIndex) => ({
                header: t(
                  `step4.riskAssessment.${subsection.name}.textRows.${valueIndex}`
                ),
                value: x.value,
              })),
              subsectionIndex + 1 !== subsections.length
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default RiskAssessmentSection;
